import Paginator from "../../components/Paginator"
import SchedaSelector from "../../components/SchedaSelector"
import StandardLayout from "../../components/StandardLayout"
import TipoDatoSelector from "../../components/TipoDatoSelector"
import { removeEmpties } from "../../helper"
import { useQsFilters } from "../../hooks/useFilters"
import { useRegoleConsegnaList } from "../../hooks/regoleconsegna"
import { Scheda, TipoDato } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  tipo_dato: params.getAll("tipo_dato") ?? [],
  scheda: params.getAll("scheda") ?? [],
  data_inizio: params.get("data_inizio") ?? "",
  data_fine: params.get("data_fine") ?? "",
})

const RegoleConsegna = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const regoleConsegna = useRegoleConsegnaList(
    removeEmpties({
      tipo_dato: filters.tipo_dato,
      scheda: filters.scheda,
      data_inizio: filters.data_inizio,
      data_fine: filters.data_fine,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="">
          <div className="page-title">Regole consegna documenti</div>
          <div className="d-flex align-items-center">
            <div className="col p-1">
              <TipoDatoSelector
                multiple
                onChange={(values: TipoDato[]) => {
                  const v = values.map((x) => x.id.toString())
                  if (v.length > 0) setFiltersDebounced({ tipo_dato: v, page: 1 })
                  else setFiltersDebounced({ tipo_dato: [] })
                }}
                currentFilter={filters.tipo_dato}
              />
            </div>
            <div className="col p-1">
              <SchedaSelector
                multiple
                onChange={(values: Scheda[]) => {
                  const v = values.map((x) => x.id.toString())
                  if (v.length > 0) setFiltersDebounced({ scheda: v, page: 1 })
                  else setFiltersDebounced({ scheda: [] })
                }}
                currentFilter={filters.scheda}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        {regoleConsegna ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr className="table-info">
                  <th>Id</th>
                  <th>Tipo dato</th>
                  <th>Scheda</th>
                  <th>Periodicità</th>
                  <th>Soggetto</th>
                  {/* <th>Dettagli</th> */}
                </tr>
              </thead>
              <tbody>
                {regoleConsegna.data?.results.map((regolaConsegna) => (
                  <tr key={regolaConsegna.id}>
                    <td>{regolaConsegna.id}</td>
                    <td>{regolaConsegna.tipo_dato_data.nome}</td>
                    <td>{`${regolaConsegna.scheda_data?.codice_scheda ?? ""} ${
                      regolaConsegna.scheda_data?.titolo ?? ""
                    }`}</td>
                    {/* <td>{regolaConsegna.scheda_data?.nome}</td> */}
                    <td>
                      {regolaConsegna.frequenza_attesa_periodo}{" "}
                      {regolaConsegna.frequenza_attesa_numero && (
                        <span>({regolaConsegna.frequenza_attesa_numero})</span>
                      )}
                    </td>
                    <td>{regolaConsegna.soggetto}</td>
                    {/* <td>
                        <Link to={`/regole-consegna/${regolaConsegna.id}`}>
                          Visualizza
                        </Link>
                      </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={regoleConsegna?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default RegoleConsegna
