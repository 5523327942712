import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import ServiziSelector from "../../components/ServiziSelector/ServiziSelector"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useQsFilters } from "../../hooks/useFilters"
import { usePianiServiziList } from "../../hooks/piani"
import MyDatePicker from "../../components/MyDataPicker/MyDatePicker"
import DownloadPianoCsvButton from "./DownloadProgrammazioneButton"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  ripianificato: params.get("ripianificato") ?? "",
  servizio: params.getAll("servizio") ?? [],
  data_inizio: params.get("data_inizio") ?? "",
  data_fine: params.get("data_fine") ?? "",
})

const Programmazioni = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const pianiServizi = usePianiServiziList(
    removeEmpties({
      ripianificato: filters.ripianificato,
      servizio: filters.servizio,
      data_inizio: filters.data_inizio,
      data_fine: filters.data_fine,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="page-title">Programmi dei servizi</div>
        <div className="d-flex flex-row align-items-center">
          {/* <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label">Stato</label>

                <div className="col-sm-10">
                  <select
                    name="attivo"
                    value={filters.attivo}
                    onChange={onFilterChange}
                    className="form-control"
                  >
                    <option value="">Tutti</option>
                    <option value="1">Attivo</option>
                    <option value="0">Non attivo</option>
                  </select>
                </div>
              </div> */}

          <div className="col p-2">
            <MyDatePicker
              type="inizio"
              startDate={filters.data_inizio}
              endDate={filters.data_fine}
              onChange={(date: Date | null) => {
                if (date) setFilters({ data_inizio: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ data_inizio: "" })
              }}
            />
          </div>
          <div className="col p-2">
            <MyDatePicker
              type="fine"
              startDate={filters.data_inizio}
              endDate={filters.data_fine}
              onChange={(date: Date | null) => {
                if (date) setFilters({ data_fine: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ data_fine: "" })
              }}
            />
          </div>

          <div className="col row p-2 d-flex align-items-center">
            <label className="col-sm-2 col-form-label-sm">{"Tipo"}</label>
            <div className="col-sm-9">
              <select
                value={filters.ripianificato}
                className="form-control col-sm-6 form-control-sm"
                onChange={(v) => setFilters({ ripianificato: v.currentTarget.value, page: 1 })}
              >
                <option value="">{"Tutti"}</option>
                <option value="0">{"Pianificato"}</option>
                <option value="1">{"Ripianificato (extra lavorazioni)"}</option>
              </select>
            </div>
          </div>

          <div className="col p-2">
            <ServiziSelector
              currentFilter={filters.servizio}
              onChange={(values) => {
                const v = values.map((x) => x.id.toString())
                if (v.length > 0) setFiltersDebounced({ servizio: v, page: 1 })
                else setFiltersDebounced({ servizio: [] })
              }}
            />
          </div>
        </div>
      </div>

      <div className="p-3">
        {pianiServizi.data ? (
          <div>
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info">
                  <th>Id piano</th>
                  <th>Codice servizio</th>
                  <th>Servizio</th>
                  <th>Tipo</th>
                  <th>Inizio validità</th>
                  <th>Fine validità</th>
                  <th>Bozza</th>
                  <th>Dettagli</th>
                </tr>
              </thead>
              <tbody>
                {pianiServizi.data?.results.map((pianoServizi) => (
                  <tr key={pianoServizi.id}>
                    <td>{pianoServizi.id}</td>
                    <td>{pianoServizi.servizio.codice_servizio}</td>
                    <td>{pianoServizi.servizio.descrizione}</td>
                    <td>{pianoServizi.ripianificato ? "Ripianificato" : "Pianificato"}</td>
                    <td>{mkStringfromDate(pianoServizi.inizio_val, "DD-MM-YYYY")}</td>
                    <td>{mkStringfromDate(pianoServizi.fine_val, "DD-MM-YYYY")}</td>
                    <td>{pianoServizi.bozza ? <span className="badge bg-primary">bozza</span> : ""}</td>
                    <td>
                      <Link to={`/programmazioni/${pianoServizi.id}`}>Visualizza</Link>
                      <DownloadPianoCsvButton id={pianoServizi.id} className="ms-2" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={pianiServizi.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default Programmazioni
