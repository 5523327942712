import { FormikHelpers } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { MdDelete, MdEdit } from "react-icons/md"
import { Link, useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import { VField } from "../../components/Fields/Fields"
import ModalConfirm from "../../components/ModalConfirm"
import ModalForm from "../../components/ModalForm"
import Paginator from "../../components/Paginator"
import StandardLayout from "../../components/StandardLayout"
import ViaSelector from "../../components/ViaSelector"
import { mkStringfromDate, removeEmpties } from "../../helper"
import {
  useDeletePianoServizio,
  useDettaglioPianiServizi,
  usePianiServiziDettaglio,
  useUpdateFineVal,
  useUpdateInizioVal,
  usePianoCsv,
} from "../../hooks/piani"
import { useQsFilters } from "../../hooks/useFilters"
import { UpdatePiano } from "../../types"
import { saveAs } from "file-saver"
import { Spinner } from "react-bootstrap"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
  vie_search: params.getAll("vie_search") ?? [],
})

const ProgrammazioneDettaglio = () => {
  const id = useParams().id!
  const navigate = useNavigate()
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const { data: pianoServizi, refetch: refetchPiani } = usePianiServiziDettaglio(id)
  const { mutateAsync: deletePianoServizio } = useDeletePianoServizio()
  const { mutateAsync: updateInizioVal } = useUpdateInizioVal(id)
  const { mutateAsync: updateFineVal } = useUpdateFineVal(id)

  const [modalConfirmState, modalConfirmActions] = useModalTrigger()
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)

  const dettagliPDS = useDettaglioPianiServizi(
    id,
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
      vie_search: filters.vie_search,
    })
  )

  const { mutateAsync: exportCSV, isPending: isDownloading } = usePianoCsv(id)
  const downloadCSV = useCallback(() => {
    exportCSV().then((docBinary) => {
      if (docBinary !== null) {
        saveAs(docBinary, `piano_${id}.csv`)
      }
    })
  }, [id])

  

  const initialValues = {
    date_start: mkStringfromDate(pianoServizi?.inizio_val) ?? "",
    date_end: mkStringfromDate(pianoServizi?.fine_val) ?? "",
    type: "start" as "start" | "end",
  }

  const [modalStateForm, modalActionsForm] = useModalTrigger<{
    date_start?: string
    date_end?: string
    type: "start" | "end"
  }>()

  const schema = yup.object().shape({
    date_start: yup.date().label("Data Inizio Validità"),
    date_end: yup.date().label("Data Fine Validità"),
  })

  const saveCustomer = useCallback(
    (
      date: { date_start?: string; date_end?: string; type: "start" | "end" },
      actions: FormikHelpers<{ date?: string; type: "start" | "end" }>
    ) => {
      const payload: UpdatePiano = {
        id: parseInt(id),
      }
      if (date.date_start) {
        payload["inizio_val"] = date.date_start
        return updateInizioVal(payload)
          .then(() => {
            refetchPiani()
            modalActionsForm.close()
            actions.resetForm()
          })
          .catch((e) => {
            actions.setErrors(e)
          })
      }
      if (date.date_end) {
        payload["fine_val"] = date.date_end
        return updateFineVal(payload)
          .then(() => {
            refetchPiani()
            modalActionsForm.close()
            actions.resetForm()
          })
          .catch((e) => {
            actions.setErrors(e)
          })
      }

      return Promise.resolve()
    },
    [modalActionsForm, modalStateForm]
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
          {pianoServizi && (
            <div style={{ flex: 1 }}>
              <div className="page-title d-flex align-items-center ">
                <Link to={`/programmazioni`}>{"Piani dei servizi"}</Link>
                {" / "}
                {pianoServizi.servizio.codice_servizio}{" "}
                {pianoServizi.bozza ? <span className="badge bg-primary m-1">{"bozza"}</span> : ""}
                {pianoServizi.cancellabile && (
                  <MdDelete
                    className="text-danger pointer"
                    onClick={() => {
                      modalConfirmActions.open("dummy")
                    }}
                  />
                )}
              </div>
              <div>
                <h6>
                  {`Scheda: `}
                  <Link to={`/catalogo-servizi/schede/${pianoServizi.servizio.scheda}`}>
                    {pianoServizi.servizio.scheda_data.descrizione}
                  </Link>{" "}
                  {`- Servizio: `}
                  <Link
                    to={`/catalogo-servizi/schede/${pianoServizi.servizio.scheda}/servizio/${pianoServizi.servizio.id}`}
                  >
                    {pianoServizi.servizio.descrizione}
                  </Link>
                </h6>
              </div>
              <div className="w-100 d-flex">
                <div className="w-50">
                  <div className="d-flex align-items-center">
                    {`Inizio validità: ${mkStringfromDate(pianoServizi.inizio_val, "DD/MM/YYYY") ?? ""}`}
                    {pianoServizi.editabile_inizio_val && (
                      <MdEdit
                        className="text-primary pointer ms-2"
                        onClick={() => {
                          modalActionsForm?.open({
                            date_start: mkStringfromDate(pianoServizi.inizio_val) ?? "",
                            type: "start",
                          })
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    {`Fine validità: ${mkStringfromDate(pianoServizi.fine_val, "DD/MM/YYYY") ?? ""}`}
                    {pianoServizi.editabile_fine_val && (
                      <MdEdit
                        className="text-primary pointer ms-2"
                        onClick={() => {
                          modalActionsForm?.open({
                            date_end: mkStringfromDate(pianoServizi.fine_val) ?? "",
                            type: "end",
                          })
                        }}
                      />
                    )}
                  </div>
                  <div>{`Tipo: ${pianoServizi.ripianificato ? "Ripianificato" : "Pianificato"}`}</div>
                </div>

                <div className="w-50">
                  <div>
                    {`Quantità totale: ${pianoServizi.totale_lavorazioni_piano?.quantita ?? ""} ${
                      pianoServizi.servizio?.scheda_data?.um ?? ""
                    }`}
                  </div>
                  <div>
                    {`N. vie: ${pianoServizi.totale_lavorazioni_piano?.numero_vie ?? ""}`}
                    {pianoServizi.totale_lavorazioni_piano?.numero_vie_agganciate && <span> ({`${pianoServizi.totale_lavorazioni_piano?.numero_vie_agganciate}`} su grafo)</span>}
                    </div>
                  <div>
                    {`N. oggetti su grafo: ${pianoServizi.totale_lavorazioni_piano?.numero_oggetti ?? ""} (${
                      pianoServizi.servizio?.scheda_data?.tipo_oggetto ?? ""
                    })`}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ width: 400 }}>
            <ViaSelector
              currentFilter={filters.vie_search}
              onChange={(values) => {
                const v = values.map((x) => x.streetcode.toString())
                if (v.length > 0) setFiltersDebounced({ vie_search: v, page: 1 })
                else setFiltersDebounced({ vie_search: [] })
              }}
            />
            <div className="mt-4">
              <button className="btn btn-primary btn-sm" onClick={downloadCSV} disabled={isDownloading}>
                {"Esporta CSV"}
                <Spinner animation="border" size="sm" role="status" hidden={!isDownloading} className="ms-2"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        {dettagliPDS.data ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Codice Viario</th>
                  <th>Nome via</th>
                  <th>{`Loc ref ${pianoServizi ? `(${pianoServizi.servizio.scheda_data.tipo_oggetto})` : ""}`}</th>
                  <th>Unità territoriale</th>
                  <th>Descrizione elemento</th>
                  <th>Data</th>
                  <th>Giorno</th>
                  <th>Ora inizio</th>
                  <th>Ora fine</th>
                  <th>Quantità</th>
                  <th>Squadra</th>
                  <th>Lato</th>
                  <th>Servizio gestore</th>
                </tr>
              </thead>
              <tbody>
                {dettagliPDS.data?.results.map((dettaglioPDS) => (
                  <tr key={dettaglioPDS.id}>
                    <td>{dettaglioPDS.streetcode}</td>
                    <td>{dettaglioPDS.toponym}</td>
                    <td>{dettaglioPDS.loc_ref}</td>
                    <td>{dettaglioPDS.unita_territoriale}</td>
                    <td>{dettaglioPDS.descrizione_elemento}</td>
                    <td>{mkStringfromDate(dettaglioPDS.data, "DD-MM-YYYY")}</td>
                    <td>{dettaglioPDS.tipo_giorno}</td>
                    <td>{dettaglioPDS.ora_inizio}</td>
                    <td>{dettaglioPDS.ora_fine}</td>
                    <td>{dettaglioPDS.quantita}</td>
                    <td>{dettaglioPDS.squadra}</td>
                    <td>{dettaglioPDS.lato}</td>
                    <td>{dettaglioPDS.servizio_gestore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      {modalStateForm.value && (
        <ModalForm<{ date_start?: string; date_end?: string; type: "start" | "end" }>
          title={modalStateForm.value?.type === "start" ? "Modifica Inizio Validità" : "Modifica Fine Validità"}
          isOpen={modalStateForm.isOpen}
          toggle={modalActionsForm.toggle}
          initialValues={modalStateForm?.value ?? initialValues}
          onClosed={modalActionsForm.onClosed}
          save={saveCustomer}
          schema={schema}
        >
          {modalStateForm.value?.type === "start" && (
            <div className="row">
              <div className="col-12">
                <VField name="date_start" label={"Data Inizio Validità"} type="date" />
              </div>
            </div>
          )}
          {modalStateForm.value?.type === "end" && (
            <div className="row">
              <div className="col-12">
                <VField name="date_end" label={"Data Fine Validità"} type="date" />
              </div>
            </div>
          )}
        </ModalForm>
      )}
      {modalConfirmState.value && (
        <ModalConfirm
          okButtonText="Elimina"
          cancelButtonText="Annulla"
          isOpen={modalConfirmState.isOpen}
          toggle={modalConfirmActions.toggle}
          onClosed={modalConfirmActions.onClosed}
          title="Elimina Programma"
          okCallBack={async () => {
            setIsModalSubmitting(true)
            return deletePianoServizio(id)
              .then(() => {
                modalConfirmActions.close()
                navigate("/programmazioni")
              })
              .finally(() => setIsModalSubmitting(false))
          }}
          body={"Confermi l'eliminazione del programma?"}
          isSubmitting={isModalSubmitting}
        />
      )}

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={dettagliPDS.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default ProgrammazioneDettaglio
