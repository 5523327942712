import Layout from "@inmagik/react-drawers"
import dayjs from "dayjs"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import React, { useCallback } from "react"
import { MdCreate, MdInfo } from "react-icons/md"
import DocumentoModal from "../../components/DocumentoModal"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import Paginator from "../../components/Paginator"
import SchedaSelector from "../../components/SchedaSelector"
import TipoDatoSelector from "../../components/TipoDatoSelector"
import { mkStringfromDate, removeEmpties } from "../../helper"
import {
  useCreateDocumento,
  useDeleteDocumento,
  useUpdateDocumento,
  useAggiornaDocumento,
} from "../../hooks/useDocumento"
import { useDocumentiPeriodoList } from "../../hooks/documentiperiodo"
import { useQsFilters } from "../../hooks/useFilters"
import {
  CreateDocumento,
  Documento,
  DocumentoPeriodo,
  RegolaConsegna,
  Scheda,
  TipoDato,
  UpdateDocumento,
} from "../../types"
import DocumentiUploadForm from "./DocumentiUploadForm"
import MyDatePicker from "../../components/MyDataPicker/MyDatePicker"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  tipo_dato: params.getAll("tipo_dato") ?? [],
  scheda: params.getAll("scheda") ?? [],
  data_inizio: params.get("data_inizio") ?? dayjs().startOf("week").format("YYYY-MM-DD"),
  data_fine: params.get("data_fine") ?? dayjs().startOf("week").add(7, "days").format("YYYY-MM-DD"),
  show_previous_versions: params.get("show_previous_versions") === "true",
})

type CurrentDoc = {
  periodo: DocumentoPeriodo
  regolaConsegna: RegolaConsegna
}

type CurrentDocAggiorna = {
  periodo: DocumentoPeriodo
  regolaConsegna: RegolaConsegna
  id: number
}

const DocumentiPeriodo = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const { mutateAsync: createDocumento } = useCreateDocumento()
  const { mutateAsync: updateDocumento } = useUpdateDocumento()
  const { mutateAsync: deleteDocumento } = useDeleteDocumento()
  const { mutateAsync: aggiornaDocumento } = useAggiornaDocumento()

  const [docModalState, docModalActions] = useModalTrigger<Documento>()
  const [docModalUploadState, docModalUploadActions] = useModalTrigger<CurrentDoc>()
  const [docModalAggiornaState, docModalAggiornaActions] = useModalTrigger<CurrentDocAggiorna>()

  // const [showPreviousVersions, setShowPreviousVersions] = useState(false)

  const regoleConsegna = useDocumentiPeriodoList(
    removeEmpties({
      tipo_dato: filters.tipo_dato,
      scheda: filters.scheda,
      data_inizio: filters.data_inizio,
      data_fine: filters.data_fine,
      page: String(filters.page),
    })
  )

  const confirmUpdateDocumento = useCallback(
    async (documento: UpdateDocumento) => {
      if (documento.id) {
        await updateDocumento(documento)
        docModalActions.close()
        docModalUploadActions.close()
        regoleConsegna.refetch()
      }
      return Promise.reject()
    },
    [updateDocumento, docModalActions, docModalUploadActions, regoleConsegna]
  )

  const confirmCreateDocumento = useCallback(
    async (documento: CreateDocumento) => {
      const result = await createDocumento(documento)
      docModalActions.open(result)
      docModalUploadActions.close()
      regoleConsegna.refetch()
    },
    [createDocumento, docModalActions, docModalUploadActions, regoleConsegna]
  )

  const confirmAggiornaDocumento = useCallback(
    async (documento: CreateDocumento) => {
      const result = await aggiornaDocumento(documento)
      docModalActions.open(result)
      docModalAggiornaActions.close()
      regoleConsegna.refetch()
    },
    [aggiornaDocumento, docModalActions, docModalAggiornaActions, regoleConsegna]
  )

  const confirmDeleteDocumento = useCallback(
    async (documentoId: number) => {
      await deleteDocumento(documentoId)
      docModalActions.close()
      regoleConsegna.refetch()
    },
    [deleteDocumento, docModalActions, regoleConsegna]
  )

  if (!regoleConsegna) return null

  return (
    <Layout className="classname-applied-to-content">
      <Layout.Top>
        <Header></Header>
      </Layout.Top>
      <Layout.Left>
        <Menu></Menu>
      </Layout.Left>
      <Layout.Right></Layout.Right>

      <Layout.Content className="bg-white h-100 overflow-auto with-sticky-footer">
        <div className="sticky-header p-3 bg-light shadow-sm">
          <div className="page-title">Consegne documenti</div>
          <div className="d-flex flex-row align-items-center">
            <div className="col p-2">
              <MyDatePicker
                required
                type="inizio"
                selected={filters.data_inizio}
                endDate={filters.data_fine}
                onChange={(date: Date | null) => {
                  if (date) return setFilters({ data_inizio: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                  return setFilters({ data_fine: dayjs().startOf("week").format("YYYY-MM-DD") })
                }}
              />
            </div>

            <div className="col p-2">
              <MyDatePicker
                required
                type="fine"
                selected={filters.data_fine}
                startDate={filters.data_inizio}
                onChange={(date: Date | null) => {
                  if (date) return setFilters({ data_fine: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                  return setFilters({ data_fine: dayjs().startOf("week").add(7, "days").format("YYYY-MM-DD") })
                }}
              />
            </div>

            <div className="col p-2">
              <TipoDatoSelector
                multiple
                onChange={(values: TipoDato[]) => {
                  const v = values.map((x) => x.id.toString())
                  if (v.length > 0) setFiltersDebounced({ tipo_dato: v, page: 1 })
                  else setFiltersDebounced({ tipo_dato: [] })
                }}
                currentFilter={filters.tipo_dato}
              />
            </div>
            <div className="col p-2">
              <SchedaSelector
                multiple
                onChange={(values: Scheda[]) => {
                  const v = values.map((x) => x.id.toString())
                  if (v.length > 0) setFiltersDebounced({ scheda: v, page: 1 })
                  else setFiltersDebounced({ scheda: [] })
                }}
                currentFilter={filters.scheda}
              />
            </div>

            <div className="col p-2">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  onChange={(e) => {
                    if (e.target.checked) setFilters({ show_previous_versions: true })
                    else setFilters({ show_previous_versions: false })
                  }}
                />
                <label className="form-check-label col-form-label-sm">Mostra versioni precedenti</label>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          {regoleConsegna ? (
            <div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className="table-info">
                    <th>Tipo dato</th>
                    <th>Scheda</th>
                    <th>Inizio</th>
                    <th>Fine</th>
                    <th>Documenti</th>
                    <th>Doc mancanti</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {regoleConsegna.data?.results.map((regolaConsegna) => (
                    <React.Fragment key={regolaConsegna.id}>
                      {regolaConsegna.documenti_periodo?.map((documentoPeriodo: DocumentoPeriodo, i: number) => (
                        <tr key={i}>
                          {i === 0 && (
                            <>
                              <td rowSpan={regolaConsegna.documenti_periodo?.length}>
                                {regolaConsegna.tipo_dato_data.nome}
                                <br />
                                <small>
                                  {regolaConsegna.frequenza_attesa_periodo}{" "}
                                  {regolaConsegna.frequenza_attesa_numero && (
                                    <span>({regolaConsegna.frequenza_attesa_numero})</span>
                                  )}
                                </small>
                              </td>
                              <td rowSpan={regolaConsegna.documenti_periodo?.length}>
                                {regolaConsegna.scheda_data?.titolo}
                              </td>
                            </>
                          )}

                          <td>{mkStringfromDate(documentoPeriodo.start_date, "YYYY-MM-DD")}</td>
                          <td>{mkStringfromDate(documentoPeriodo.end_date, "YYYY-MM-DD")}</td>
                          <td>
                            <div>
                              {documentoPeriodo.docs.map((doc) => (
                                <div key={doc.id}>
                                  {(!doc.versione_aggiornata || filters.show_previous_versions) && (
                                    <div>
                                      <div>
                                        {doc.titolo}: {doc.filename}
                                        {doc.bozza && <b> (bozza)</b>}{" "}
                                        <button
                                          className={`btn btn-sm ms-2 ${doc.bozza ? "btn-warning" : "btn-primary "}`}
                                          onClick={() => docModalActions.open(doc)}
                                        >
                                          {doc.bozza ? <MdCreate size="14" /> : <MdInfo size="14" />}
                                        </button>
                                        {regolaConsegna.is_owner && !doc.bozza && !doc.versione_aggiornata && (
                                          <button
                                            className={`btn btn-sm ms-2 btn-warning`}
                                            onClick={() =>
                                              docModalAggiornaActions.open({
                                                periodo: documentoPeriodo,
                                                regolaConsegna,
                                                id: doc.id,
                                              })
                                            }
                                          >
                                            AGGIORNA
                                          </button>
                                        )}
                                      </div>
                                      <small>{mkStringfromDate(doc.data_modifica, "DD/MM/YYYY HH:mm")}</small>
                                      {doc.versione_aggiornata ? (
                                        <small className="ms-2">
                                          <i>VERSIONE OBSOLETA</i>
                                        </small>
                                      ) : (
                                        <small className="ms-2">
                                          <i>VERSIONE CORRENTE</i>
                                        </small>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            {documentoPeriodo &&
                              regolaConsegna &&
                              regolaConsegna.is_owner &&
                              (!regolaConsegna.frequenza_attesa_periodo ||
                                (documentoPeriodo.expected &&
                                  documentoPeriodo.docs.length < documentoPeriodo.expected)) && (
                                <button
                                  className="btn btn-sm btn-primary"
                                  type="button"
                                  onClick={() => {
                                    docModalUploadActions.open({
                                      periodo: documentoPeriodo,
                                      regolaConsegna,
                                    })
                                  }}
                                >
                                  UPLOAD
                                </button>
                              )}
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                documentoPeriodo.missing && documentoPeriodo.missing > 0 ? "bg-danger" : "bg-success"
                              }`}
                            >
                              {documentoPeriodo.missing}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="sticky-footer bg-light border-top">
          <Paginator
            count={regoleConsegna?.data?.count ?? 0}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          ></Paginator>
        </div>
      </Layout.Content>

      {docModalUploadState.value && (
        <DocumentiUploadForm
          isOpen={docModalUploadState.isOpen}
          toggle={docModalUploadActions.toggle}
          onClosed={docModalUploadActions.onClosed}
          periodo={docModalUploadState.value.periodo}
          regolaConsegna={docModalUploadState.value.regolaConsegna}
          onSave={(doc) => confirmCreateDocumento(doc)}
        />
      )}

      {docModalState.value && (
        <DocumentoModal
          isOpen={docModalState.isOpen}
          toggle={docModalActions.toggle}
          onClosed={docModalActions.onClosed}
          idDocumento={docModalState.value.id}
          onDelete={(id) => confirmDeleteDocumento(id)}
          onUpdate={(documento) => confirmUpdateDocumento(documento)}
          showEditCommands
        />
      )}

      {docModalAggiornaState.value && (
        <DocumentiUploadForm
          isOpen={docModalAggiornaState.isOpen}
          toggle={docModalAggiornaActions.toggle}
          onClosed={docModalAggiornaActions.onClosed}
          periodo={docModalAggiornaState.value.periodo}
          regolaConsegna={docModalAggiornaState.value.regolaConsegna}
          onSave={(doc) => confirmAggiornaDocumento({ id: docModalAggiornaState.value?.id, ...doc })}
        />
      )}
    </Layout>
  )
}

export default DocumentiPeriodo
