import dayjs from "dayjs"
import weekOfYear from "dayjs/plugin/weekOfYear"
import { Link, useParams } from "react-router-dom"
import StandardLayout from "../../components/StandardLayout"
import {
  useReportScheda,
  useReportSchedaExcel,
  useReportSchedaHtml,
  useReportSchedaPdf,
} from "../../hooks/reportscheda"
import { Button } from "react-bootstrap"
import { MdDownload } from "react-icons/md"
import { useCallback } from "react"
import { saveAs } from "file-saver"

dayjs.extend(weekOfYear)

export default function ReportDettaglio() {
  const idReport = useParams().id!
  const { data: report } = useReportScheda(idReport)
  const { data: reportHtml } = useReportSchedaHtml(idReport)
  const { mutateAsync: exportPDF } = useReportSchedaPdf(idReport)
  const { mutateAsync: exportExcel } = useReportSchedaExcel(idReport)

  const downloadPDF = useCallback(() => {
    exportPDF().then((docBinary) => {
      if (docBinary !== null) {
        saveAs(docBinary, `report_monitoraggio_${idReport}`)
      }
    })
  }, [idReport])
  const downloadExcel = useCallback(() => {
    exportExcel().then((docBinary) => {
      if (docBinary !== null) {
        saveAs(docBinary, `report_monitoraggio_${idReport}`)
      }
    })
  }, [idReport])

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between w-100">
          <div className="page-title">
            <Link to="/reports">Reports</Link> / {report?.id}
          </div>
          <div className="d-flex gap-2">
            <Button size="sm" onClick={() => downloadExcel()}>
              <MdDownload /> Excel
            </Button>
            <Button size="sm" onClick={() => downloadPDF()}>
              <MdDownload /> PDF
            </Button>
          </div>
        </div>
      </div>
      {reportHtml && <div className="p-3" dangerouslySetInnerHTML={{ __html: reportHtml }} />}
    </StandardLayout>
  )
}
