import dayjs from "dayjs"
import { Button } from "react-bootstrap"
import { Link, useMatch, useNavigate } from "react-router-dom"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate } from "../../helper"
import { Scheda } from "../../types"
import { useReportSchedeList } from "../../hooks/reportscheda"
import { useQsFilters } from "../../hooks/useFilters"
import Paginator from "../../components/Paginator"
import { get } from "lodash"

function getClassificazioneLink(schedaServizio: Scheda) {
  const classificazione = schedaServizio.classificazione
  if (!classificazione) {
    return ""
  }
  return (
    <Link to={`/classificazioni/${classificazione}?scheda=${schedaServizio.id}`}>
      {schedaServizio.classificazione_nome}
    </Link>
  )
}

type Props = {
  schedaServizio: Scheda
}

function getDettagliPianificazione(schedaServizio: Scheda) {
  if (!schedaServizio) {
    return
  }
  return (
    <>
      {schedaServizio.pianificazione === "pds" && (
        <span>
          {schedaServizio.pds_richiesto_giorno && <span>Giorno settimana</span>}
          {schedaServizio.pds_richiesta_data && <span>Data</span>}
          {schedaServizio.pds_richiesta_ora && <span> + fascia oraria. </span>}
        </span>
      )}
    </>
  )
}

function getDettaglioElementoGeo(schedaServizio: Scheda) {
  return (
    <>
      {schedaServizio.tipo_oggetto}
      {schedaServizio.pds_richiesto_oggetto && <span> (obbligatorio)</span>}
    </>
  )
}

function getFrequenzaServizio(schedaServizio: Scheda) {
  if (schedaServizio.classificazione) {
    return "Vedi classificazione"
  }
  return `${schedaServizio.frequenza_numero_default} / ${schedaServizio.frequenza_periodo_default}`
}

function getSla(schedaServizio: Scheda) {
  if (schedaServizio.classificazione) {
    return "Vedi classificazione"
  }
  return `${schedaServizio.tempo_recupero_sla_ore_default} ore`
}

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  tipo: params.get("tipo") ?? "",
  scheda: params.get("scheda") ?? [],
})

export default function SchedaServizioDettaglio({ schedaServizio }: Props) {
  const navigate = useNavigate()
  const match = useMatch("/catalogo-servizi/schede/:idScheda/:type")
  const type = match?.params.type ?? "info"
  const { filters, uiFilters, setFilters } = useQsFilters(initFilters)
  const { data: reports } = useReportSchedeList({
    tipo: "m",
    scheda: schedaServizio.id,
    page: String(filters.page),
  })
  return (
    <StandardLayout>
      <div className="d-flex align-items-start justify-content-between sticky-header shadow-sm p-3">
        {schedaServizio && (
          <div>
            <div className="page-title">
              {schedaServizio.titolo} - {schedaServizio.codice_scheda}
            </div>
            <div className="py-1">
              <Link
                className={`btn btn-sm ${type === "info" ? "btn-primary" : "btn-default"} me-2`}
                to={`/catalogo-servizi/schede/${schedaServizio.id}`}
                // state={{ schedaServizio, servizio }}
              >
                Informazioni
              </Link>
              <Link
                className={`btn btn-sm ${type === "reports" ? "btn-primary" : "btn-default"} me-2`}
                to={`/catalogo-servizi/schede/${schedaServizio.id}/reports`}
                // state={{ schedaServizio, servizio }}
              >
                Reports
              </Link>
            </div>
          </div>
        )}
        <Button
          size="sm"
          onClick={() => navigate(`/overview/pianificato/${schedaServizio.id}/${dayjs().format("YYYY-MM-DD")}`)}
        >
          Overview
        </Button>
      </div>
      {schedaServizio && (
        <div className="p-3">
          <div className="mt-2">
            <PropertyList
              propLists={[
                [
                  ["ID", schedaServizio.id],
                  ["Descrizione", schedaServizio.descrizione],
                  ["Codice scheda", schedaServizio.codice_scheda],
                  ["Codice macroscheda Comune", schedaServizio.codice_macroscheda_comune],
                  ["Inizio validità", mkStringfromDate(schedaServizio.inizio_val, "YYYY-MM-DD")],
                  ["Tipo servizio", `${schedaServizio.tipo_servizio} (${schedaServizio.tipo_servizio_label})`],
                  ["Periodicità report", schedaServizio.periodicita_report_label],
                ],
                [
                  ["Unità di misura", schedaServizio.um],
                  ["Tipo oggetto", getDettaglioElementoGeo(schedaServizio)],
                  ["Programmazione", schedaServizio.pianificazione],
                  ["Dettagli programmazione", getDettagliPianificazione(schedaServizio)],
                  ["Frequenza servizio", getFrequenzaServizio(schedaServizio)],
                  ["SLA", getSla(schedaServizio)],
                  ["Classificazione oggetti", getClassificazioneLink(schedaServizio)],
                ],
              ]}
            ></PropertyList>

            {type === "info" && (
              <>
                <h4 className="mt-3">Servizi</h4>
                <table className="table table-hover">
                  <thead>
                    <tr className="table-info">
                      <th>Codice servizio</th>
                      <th>Descrizione</th>
                      <th>Inizio validità</th>
                      <th>Fine validità</th>
                      <th>Gestore</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schedaServizio.servizi?.map((servizio) => (
                      <tr key={servizio.id}>
                        <td>
                          <Link to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}>
                            {servizio.codice_servizio}
                          </Link>
                        </td>

                        <td>{servizio.descrizione}</td>
                        <td>{mkStringfromDate(servizio.inizio_val, "YYYY-MM-DD")}</td>
                        <td>{mkStringfromDate(servizio.fine_val, "YYYY-MM-DD")}</td>
                        {servizio.gestore_data ? <td>{servizio.gestore_data.codice}</td> : <td></td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {type === "reports" && reports && (
              <div>
                <h4 className="mt-3">Reports Monitoraggio</h4>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr className="table-info">
                      <th>Scheda</th>
                      <th>Servizio</th>
                      <th>Tipo</th>
                      <th>Data caricamento</th>
                      <th>Inizio validità</th>
                      <th>Fine validità</th>
                      <th>Dettagli</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports.results.map((report) => (
                      <tr key={report.id}>
                        <td>{get(report, "scheda_data.codice_scheda")}</td>
                        <td>{get(report, "servizio_data.codice_servizio")}</td>
                        <td>{report.tipo_display}</td>
                        <td>{mkStringfromDate(report.timestamp, "DD/MM/YYYY HH:mm")}</td>
                        <td>{mkStringfromDate(report.inizio_val, "DD/MM/YYYY")}</td>
                        <td>{mkStringfromDate(report.fine_val, "DD/MM/YYYY")}</td>
                        {!report.in_progress && (
                          <td>
                            {report.tipo === "m" && report.servizio === null ? (
                              <Link to={`/reports-monitoraggio/${report.id}`}>Visualizza</Link>
                            ) : (
                              <Link to={`/reports/${report.id}`}>Visualizza</Link>
                            )}
                          </td>
                        )}
                        {report.in_progress && <td>Aggiornamento in corso</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
      {type === "reports" && (
        <div className="sticky-footer bg-light border-top">
          <Paginator
            count={reports?.count ?? 0}
            currentPage={uiFilters.page}
            goToPage={(page) => setFilters({ page })}
          />
        </div>
      )}
    </StandardLayout>
  )
}
