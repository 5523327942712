import dayjs from "dayjs"

export const removeEmpties = (obj: Record<string, string | string[] | number | number[] | undefined | null>) => {
  const result: Record<string, string | string[] | number | number[]> = {}
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      result[key] = value
    }
  }
  return result
}

export const mkDatefromString = (date: string | null | undefined) => {
  if (!date) return null
  return dayjs(date).toDate()
}

export const mkStringfromDate = (date: Date | null | undefined, format: string = "YYYY-MM-DD"): string | undefined => {
  if (!date) return undefined
  return dayjs(date).format(format)
}

export function ensureDate(date: Date | string | null | undefined): Date | null {
  if (date === null || date === undefined) return null
  if (date instanceof Date) return date
  return mkDatefromString(date)
}

export function encodeQS(params: Record<string, string | number | string[] | number[] | null | undefined>) {
  const result = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        if (item !== null && item !== undefined) {
          result.append(key, item.toString())
        }
      }
    } else {
      if (value !== null && value !== undefined) {
        result.append(key, value.toString())
      }
    }
  }
  return result
}

