import { Link } from "react-router-dom"
import MyDatePicker from "../../components/MyDataPicker/MyDatePicker"
import Paginator from "../../components/Paginator"
import ServiziSelector from "../../components/ServiziSelector/ServiziSelector"
import StandardLayout from "../../components/StandardLayout"
import ViaSelector from "../../components/ViaSelector"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useNonEseguitoList } from "../../hooks/noneseguito"
import { useQsFilters } from "../../hooks/useFilters"
import { NonEseguito } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  vie_search: params.getAll("vie_search") ?? [],
  servizio: params.getAll("servizio") ?? [],
  data_inizio: params.get("data_inizio") ?? "",
  data_fine: params.get("data_fine") ?? "",
  bozza: params.get("data_fine") ?? false,
})

export function getServizioLink(nonEseguito: NonEseguito) {
  const servizio = nonEseguito.servizio
  const scheda = nonEseguito.scheda_servizio
  if (!servizio || !scheda) {
    return ""
  }
  return <Link to={`/catalogo-servizi/schede/${scheda}/servizio/${servizio}`}>{nonEseguito.codice_servizio}</Link>
}

const NonEseguitoPage = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const nonEseguiti = useNonEseguitoList(
    removeEmpties({
      vie_search: filters.vie_search,
      servizio: filters.servizio,
      data_inizio: filters.data_inizio,
      data_fine: filters.data_fine,
      page: String(filters.page),
      bozza: filters.bozza.toString(),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="">
          <div className="page-title">Non eseguito</div>
          <div className="d-flex align-items-center">
            {/* <div className="form-group row w-25 p-1">
                <label className="col-sm-2 col-form-label">Stato</label>

                <div className="col-sm-10">
                  <select
                    name="attivo"
                    value={filters.attivo}
                    onChange={onFilterChange}
                    className="form-control"
                  >
                    <option value="">Tutti</option>
                    <option value="1">Attivo</option>
                    <option value="0">Non attivo</option>
                  </select>
                </div>
              </div> */}

            <div className="col p-2">
              <MyDatePicker
                type="inizio"
                startDate={filters.data_inizio}
                endDate={filters.data_fine}
                onChange={(date) => {
                  if (date) setFilters({ data_inizio: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                  else setFilters({ data_inizio: "" })
                }}
              />
            </div>

            <div className="col p-2">
              <MyDatePicker
                type="fine"
                startDate={filters.data_inizio}
                endDate={filters.data_fine}
                onChange={(date) => {
                  if (date) setFilters({ data_fine: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                  else setFilters({ data_fine: "" })
                }}
              />
            </div>

            <div className="col p-2">
              <ServiziSelector
                currentFilter={filters.servizio}
                onChange={(values) => {
                  const v = values.map((x) => x.id.toString())
                  if (v.length > 0) setFiltersDebounced({ servizio: v, page: 1 })
                  else setFiltersDebounced({ servizio: [] })
                }}
              />
            </div>

            <div className="col p-2">
              <ViaSelector
                currentFilter={filters.vie_search}
                onChange={(values) => {
                  const v = values.map((x) => x.streetcode.toString())
                  if (v.length > 0) setFiltersDebounced({ vie_search: v, page: 1 })
                  else setFiltersDebounced({ vie_search: [] })
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        {nonEseguiti.data ? (
          <div>
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info align-top">
                  <th>ID</th>
                  <th>Servizio</th>
                  <th>Data</th>
                  <th>Nome via</th>
                  <th>Streetcode</th>
                  <th>Loc_ref</th>
                  <th>Lato</th>
                  <th>Descrizione elemento</th>
                  <th>Unità territoriale</th>
                  <th>Causale</th>
                  <th>Esecuzione</th>
                  <th>Minuti ritardo</th>
                  <th>Quantità</th>
                </tr>
              </thead>
              <tbody>
                {nonEseguiti.data?.results.map((nonEseguito: NonEseguito) => (
                  <tr key={nonEseguito.id}>
                    <td>{nonEseguito.id}</td>
                    <td>{getServizioLink(nonEseguito)}</td>
                    <td>{mkStringfromDate(nonEseguito.data, "DD/MM/YYYY")}</td>
                    <td>{nonEseguito.toponym}</td>
                    <td>{nonEseguito.streetcode}</td>
                    <td>{nonEseguito.loc_ref}</td>
                    <td>{nonEseguito.lato}</td>
                    <td>{nonEseguito.descrizione_elemento}</td>
                    <td>{nonEseguito.unita_territoriale}</td>
                    <td>{nonEseguito.causale}</td>
                    <td>{mkStringfromDate(nonEseguito.dataora_esecuzione, "DD/MM/YYYY HH:mm")}</td>
                    <td>{nonEseguito.minuti_ritardo}</td>
                    <td>{nonEseguito.quantita}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={nonEseguiti.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default NonEseguitoPage
