import { FormikErrors } from "formik"
import { ReactNode } from "react"
import { Alert } from "react-bootstrap"

interface GenericError {
  status?: number
}

interface GenericFormErrorProps<FormValues> {
  errors: FormikErrors<FormValues> & { __noFieldsServerError?: GenericError }
  showError?: boolean
  badRequestError?: string
  messageParser?: (error: any) => ReactNode
}

export default function GenericFormError<FormValues>({
  // errors from Formik
  errors,
  // critical (only no internetl or 500) | all (all shit)
  showError = false,
  badRequestError,
  messageParser,
}: GenericFormErrorProps<FormValues>) {
  const error = errors.__noFieldsServerError
  if (error) {
    let msg = null

    if (messageParser) {
      msg = messageParser(error)
    }

    if (msg === null) {
      // Critical errors
      if (error.status === undefined) {
        // No internet (i think)
        msg = "Please check yuor internet connection and try again."
      } else if (error.status >= 500) {
        // Server error
        msg = "A server error occured, please try again later."
      } else if (badRequestError) {
        msg = badRequestError
      } else if (showError) {
        msg = JSON.stringify(error)

        // Trim error to a max of 600 chars
        if (msg.length > 600) {
          msg = msg.substring(0, 600) + "..."
        }

        // TODO: Handle 400 not related 2 fields ES:. no_fields_errors of Django
      }
    }

    if (msg) {
      return <Alert variant="danger">{msg}</Alert>
    }
  }

  return null
}
