import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"

export function MakeAuthTools<User, Tokens, Credentials>() {
  type AuthContextType = {
    user: User | null
    tokens: Tokens | null
    performLogin: (credentials: Credentials) => Promise<void>
    performLogout: () => void

    loginError: Error | null
    isLoggingIn: boolean
    refreshError: Error | null
    isRefreshing: boolean

    // loading state and error state for user data fetching
    userDataLoading: boolean
    userDataError: Error | null
  }

  const AuthContext = createContext<AuthContextType>({
    user: null,
    tokens: null,
    performLogin: async () => {},
    performLogout: () => {},
    loginError: null,
    isLoggingIn: false,
    refreshError: null,
    isRefreshing: false,
    userDataLoading: false,
    userDataError: null,
  })

  function loadTokensFromStorage(): Tokens | null {
    const tokens = localStorage.getItem("tokens")
    if (tokens) {
      return JSON.parse(tokens)
    }
    return null
  }

  function saveTokensToStorage(tokens: Tokens) {
    localStorage.setItem("tokens", JSON.stringify(tokens))
  }

  function removeTokensFromStorage() {
    localStorage.removeItem("tokens")
  }

  type AuthProviderProps = {
    children?: React.ReactNode
    login: (credentials: Credentials) => Promise<Tokens | null>
    refresh: (tokens: Tokens) => Promise<Tokens | null>
    getUserData: (tokens: Tokens) => Promise<User | null>
    getTokenExpireDate: (tokens: Tokens) => Date
  }

  function AuthProvider({ children, login, refresh, getUserData, getTokenExpireDate }: AuthProviderProps) {
    const [tokens, setTokens] = useState<Tokens | null>(loadTokensFromStorage())
    const [user, setUser] = useState<User | null>(null)
    const [loaded, setLoaded] = useState(false)

    const [loginError, setLoginError] = useState<Error | null>(null)
    const [refreshError] = useState<Error | null>(null)

    const fetchUserData = useCallback(
      async (tokens: Tokens) => {
        const userData = await getUserData(tokens)
        if (userData !== null) {
          setUser(userData)
          return userData
        } else {
          return null
        }
      },
      [getUserData]
    )

    const refreshTokens = useCallback(
      async (currentTokens: Tokens) => {
        const newTokens = await refresh(currentTokens)
        if (newTokens !== null) {
          setTokens(newTokens)
          saveTokensToStorage(newTokens)
          return newTokens
        } else {
          return null
        }
      },
      [refresh]
    )

    useEffect(() => {
      if (tokens) {
        fetchUserData(tokens).finally(() => setLoaded(true))
      } else {
        setLoaded(true)
      }
    }, [fetchUserData, tokens])

    useEffect(() => {
      // TODO: refresh logic should be optional
      if (tokens) {
        const exp = getTokenExpireDate(tokens)
        // refresh tokens 1 minute before they expire
        // if they are already expired, do nothing
        // #TODO: add parameter for time before expiration

        const now = Date.now()
        let timeToExpire = exp.getTime() - now
        if (timeToExpire < 0) {
          return
        }
        timeToExpire = Math.max(timeToExpire - 1000 * 60 * 1, 0)

        const timeout = setTimeout(() => {
          refreshTokens(tokens)
        }, timeToExpire)
        return () => clearTimeout(timeout)
      }
    }, [getTokenExpireDate, refreshTokens, tokens])

    const performLogin = useCallback(
      async (credentials: Credentials) => {
        const loginTokens = await login(credentials).catch((e) => {
          setLoginError(e)
          return null
        })
        if (loginTokens !== null) {
          const user = await fetchUserData(loginTokens)
          if (user) {
            setTokens(loginTokens)
            saveTokensToStorage(loginTokens)
            setLoginError(null)
          }
        }
      },
      [fetchUserData, login]
    )

    const performLogout = useCallback(() => {
      removeTokensFromStorage()
      setUser(null)
      setTokens(null)
    }, [])

    const ctx = useMemo(() => {
      return {
        user,
        performLogin,
        performLogout,
        tokens,
        loginError,
        isLoggingIn: false,
        refreshError,
        isRefreshing: false,
        userDataLoading: false,
        userDataError: null,
      }
    }, [performLogin, performLogout, tokens, user, loginError, refreshError])

    if (!loaded) {
      return null
    }

    return <AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>
  }

  function useAuth() {
    const context = useContext<AuthContextType>(AuthContext as unknown as React.Context<AuthContextType>)
    if (!context) {
      throw new Error("useAuth must be used under AuthProvider")
    }
    return context
  }

  return [AuthProvider, useAuth] as [typeof AuthProvider, typeof useAuth]
}
