import { Formik } from "formik"
import { Button, Modal, Spinner } from "react-bootstrap"
import { MdSave } from "react-icons/md"

interface ModalFormProps<T> {
  isOpen: boolean
  toggle: () => void
  onClosed?: () => void
  title?: string
  initialValues: T
  schema: any
  save: (record: T, actions: any) => Promise<any>
  children?: React.ReactNode
  labelSave?: string
  size?: "sm" | "lg" | "xl"
}

export default function ModalForm<T extends {}>({
  isOpen,
  toggle,
  onClosed,
  title,
  initialValues,
  schema,
  save,
  children,
  labelSave,
  size,
}: ModalFormProps<T>) {
  return (
    <Formik initialValues={initialValues} onSubmit={save} validationSchema={schema} enableReinitialize>
      {({ handleSubmit, isSubmitting }) => (
        <Modal show={isOpen} onHide={toggle} onExited={onClosed} centered size={size}>
          <form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="page-title">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 200px)",
                overflowY: "auto",
              }}
            >
              {children}
            </Modal.Body>

            <Modal.Footer>
              <div className="w-100 d-flex align-items-center justify-content-end">
                <Button type="button" onClick={toggle} variant="outline-primary" className="btn-sm me-2">
                  Chiudi
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="btn-sm text-white d-flex align-items-center"
                >
                  {!isSubmitting && <MdSave className="me-2" />}
                  {isSubmitting && <Spinner size="sm" className="me-2" />}
                  {labelSave || "Salva"}
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
