import { Link, useParams } from "react-router-dom"
import Paginator from "../../components/Paginator"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useDettaglioReportSchedeList, useReportScheda } from "../../hooks/reportscheda"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
})

export default function ReportDettaglio() {
  const { filters, uiFilters, setFilters } = useQsFilters(initFilters)
  const idReport = useParams().id!

  const { data: report } = useReportScheda(idReport)
  const { data: dettagli } = useDettaglioReportSchedeList(
    removeEmpties({
      report: idReport,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="page-title">
          <Link to="/reports">Reports</Link> / {report?.id}
        </div>
      </div>
      <div className="p-3">
        {report && (
          <div className="mt-2">
            <PropertyList
              propLists={[
                [
                  ["Id", report.id],
                  ["Tipo", report.tipo_display],
                  ["Scheda", report.scheda_data?.codice_scheda],
                  ["Servizio", report.servizio_data?.codice_servizio],
                ],
                [
                  ["Operazione", report.operazione ?? "-"],
                  ["Inizio validità", mkStringfromDate(report.inizio_val, "DD/MM/YYYY")],
                  ["Fine validità", mkStringfromDate(report.fine_val, "DD/MM/YYYY")],
                  ["Data caricamento", mkStringfromDate(report.updated, "DD/MM/YYYY HH:mm")],
                ],
              ]}
            ></PropertyList>
          </div>
        )}

        {dettagli && (
          <div className="mt-4">
            <h3>Dettagli</h3>
            <table className="table table-bordered table-hover">
              <thead>
                <tr className="table-info">
                  <th>Giorno</th>
                  <th>Classe oggetti</th>
                  {report?.tipo === "p" && <th>Pianificato</th>}
                  {report?.tipo === "n" && <th>Non eseguito</th>}
                </tr>
              </thead>
              {dettagli?.results.length > 0 && (
                <tbody>
                  {dettagli?.results.map((dettaglio) => (
                    <tr key={dettaglio.id}>
                      <td>{mkStringfromDate(dettaglio.giorno, "DD/MM/YYYY")}</td>
                      <td>{dettaglio.nome_classe_oggetti}</td>
                      {report?.tipo === "p" && <td>{dettaglio.pianificato}</td>}
                      {report?.tipo === "n" && <td>{dettaglio.non_eseguito}</td>}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        )}
      </div>
      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={dettagli?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}
