import { usePianoCsv } from "../../hooks/piani"
import { saveAs } from "file-saver"
import { useCallback } from "react"
import { Spinner } from "react-bootstrap"

export default function DownloadPianoCsvButton({ id, className='' }: { id: number, className?: string }) {
  const { mutateAsync: exportCSV, isPending } = usePianoCsv(id.toString())
  const downloadCSV = useCallback(() => {
    exportCSV().then((docBinary) => {
      if (docBinary !== null) {
        saveAs(docBinary, `piano_${id}.csv`)
      }
    })
  }, [id])

  return (
    <button className={`btn btn-primary btn-sm ${className}`} onClick={downloadCSV}>
      {isPending ? <Spinner animation="border" size="sm" /> : "CSV"}
    </button>
  )
}
