import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback } from "react"
import { MdCreate } from "react-icons/md"
import DocumentoModal from "../../components/DocumentoModal"
import MyDatePicker from "../../components/MyDataPicker/MyDatePicker"
import Paginator from "../../components/Paginator"
import SchedaSelector from "../../components/SchedaSelector"
import StandardLayout from "../../components/StandardLayout"
import TipoDatoSelector from "../../components/TipoDatoSelector"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useDeleteDocumento, useDocumentiList, useUpdateDocumento } from "../../hooks/useDocumento"
import { useQsFilters } from "../../hooks/useFilters"
import { Documento, Scheda, TipoDato, UpdateDocumento } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  tipo_dato: params.getAll("tipo_dato") ?? [],
  scheda: params.getAll("scheda") ?? [],
  periodo_inizio: params.get("periodo_inizio") ?? "",
  periodo_fine: params.get("periodo_fine") ?? "",
  show_previous_versions: params.get("show_previous_versions") === "true",
})

const Documenti = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const { mutateAsync: deleteDocumento } = useDeleteDocumento()
  const { mutateAsync: updateDocumento } = useUpdateDocumento()
  const [docModalState, docModalActions] = useModalTrigger<Documento>()
  const [docModalBozzaState, docModalBozzaActions] = useModalTrigger<Documento>()

  const documenti = useDocumentiList(
    removeEmpties({
      tipo_dato: filters.tipo_dato,
      scheda: filters.scheda,
      periodo_inizio: filters.periodo_inizio,
      periodo_fine: filters.periodo_fine,
      page: String(filters.page),
      show_previous_versions: String(filters.show_previous_versions),
    })
  )

  const confirmUpdateDocumento = useCallback(
    async (documento: UpdateDocumento) => {
      await updateDocumento(documento)
      docModalActions.close()
      documenti.refetch()
    },
    [docModalActions, documenti, updateDocumento]
  )

  const confirmDeleteDocumento = useCallback(
    async (documentoId: number) => {
      await deleteDocumento(documentoId)
      docModalActions.close()
      documenti.refetch()
    },
    [deleteDocumento, docModalActions, documenti]
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-white p-3 shadow-sm">
        <div className="page-title">Archivio documenti</div>
        <div className="d-flex flex-row align-items-center">
          <div className="col p-2">
            <MyDatePicker
              type="inizio"
              startDate={filters.periodo_inizio}
              endDate={filters.periodo_fine}
              onChange={(date) => {
                if (date) setFilters({ periodo_inizio: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ periodo_inizio: "" })
              }}
            />
          </div>
          <div className="col p-2">
            <MyDatePicker
              type="fine"
              startDate={filters.periodo_inizio}
              endDate={filters.periodo_fine}
              onChange={(date) => {
                if (date) setFilters({ periodo_fine: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ periodo_fine: "" })
              }}
            />
          </div>
          <div className="col p-2">
            <TipoDatoSelector
              multiple
              onChange={(values: TipoDato[]) => {
                const v = values.map((x) => x.id.toString())
                if (v.length > 0) setFiltersDebounced({ tipo_dato: v, page: 1 })
                else setFiltersDebounced({ tipo_dato: [] })
              }}
              currentFilter={filters.tipo_dato}
            />
          </div>

          <div className="col p-2">
            <SchedaSelector
              multiple
              onChange={(values: Scheda[]) => {
                const v = values.map((x) => x.id.toString())
                if (v.length > 0) setFiltersDebounced({ scheda: v, page: 1 })
                else setFiltersDebounced({ scheda: [] })
              }}
              currentFilter={filters.scheda}
            />
          </div>
          <div className="col p-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={(e) => {
                  if (e.target.checked) setFilters({ show_previous_versions: true })
                  else setFilters({ show_previous_versions: false })
                }}
              />
              <label className="form-check-label col-form-label-sm">Mostra versioni precedenti</label>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        {documenti ? (
          <div>
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info">
                  <th>Tipo dato</th>
                  <th>Scheda</th>
                  <th>Titolo</th>
                  <th>Nome file</th>
                  <th>Periodo</th>
                  <th>Dettagli</th>
                </tr>
              </thead>
              <tbody>
                {documenti.data?.results.map((documento: Documento) => (
                  <tr key={documento.id}>
                    <td>{documento.tipo_dato_data.nome}</td>
                    <td>{documento.scheda_data?.titolo}</td>
                    <td>
                      {documento.bozza && <b>Bozza </b>}
                      {documento.titolo}
                      {documento.versione_aggiornata && (
                        <div>
                          <small>
                            <i>Versione obsoleta</i>
                          </small>
                        </div>
                      )}
                    </td>
                    <td>{documento.filename}</td>
                    <td>
                      {`${mkStringfromDate(documento.periodo_inizio, "DD/MM/YYYY")} - ${mkStringfromDate(
                        documento.periodo_fine,
                        "DD/MM/YYYY"
                      )}`}
                    </td>
                    <td>
                      <button
                        className={`btn btn-sm ms-1 ${documento.bozza ? "btn-warning" : "btn-primary "}`}
                        onClick={() => {
                          documento.bozza ? docModalBozzaActions.open(documento) : docModalActions.open(documento)
                        }}
                      >
                        <MdCreate size="14" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={documenti?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>

      {docModalState.value && (
        <DocumentoModal
          isOpen={docModalState.isOpen}
          toggle={docModalActions.toggle}
          onClosed={docModalActions.onClosed}
          idDocumento={docModalState.value.id}
        />
      )}

      {docModalBozzaState.value && (
        <DocumentoModal
          isOpen={docModalBozzaState.isOpen}
          toggle={docModalBozzaActions.toggle}
          onClosed={docModalBozzaActions.onClosed}
          idDocumento={docModalBozzaState.value.id}
          onDelete={(id) => confirmDeleteDocumento(id)}
          onUpdate={(documento) => confirmUpdateDocumento(documento)}
          showEditCommands
        />
      )}
    </StandardLayout>
  )
}

export default Documenti
